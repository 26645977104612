import React from "react";

// Assets
import WorkingImg from "../../assets/GK_working_v2.png"

const Contact = () => {
    return (
        <>
        <main className="container-fluid" style={{backgroundColor: "#dbdddd"}} id="kontakt">
            <section className="py-5 mx-5 text-center" >
                <h2 className="mt-5" style={{color: "#333"}}>Kontakt GK-Tømrer</h2>
                <hr style={{border: "2px solid #8E252C", width: "200px", margin: "20px auto"}} />
                <p id="contactText">
                    Vi kan træffes alle ugens hverdage, enten på telefon eller e-mail. 
                    Tag kontakt og få en helt uforpligtende snak omkring, hvad vi kan hjælpe dig med.
                </p>
            </section>
            <section className="row text-center pb-5" id="contactInfoText">
                <article className="col-lg-6 col-12">
                    <i className="fas fa-map-marker-alt mb-2" style={{fontSize: "40px", color: "#8E252C"}}></i>
                    <h4>Firmaoplysninger:</h4>
                    <p style={contactP}>Fynslundvej 80, 6064 Jordrup<br/>CVR: <span className="bold">41892374</span></p>
                </article>
                <article className="col-lg-6 col-12">
                    <i className="fas fa-comment mb-2" style={{fontSize: "40px", color: "#8E252C"}}></i>
                    <h4>Kontaktoplysninger:</h4>
                    <p style={contactP}>Telefon: <span className="bold">26 27 48 47</span><br/>Email: <span className="bold">gunnergk@outlook.dk</span></p>
                </article>
            </section>
        </main>
        <section style={ContactBottomStyle} className="py-5 px-5 text-center" >
            <h3 className="pt-5 mb-5">Få et uforpligtende tilbud</h3>
            <p style={contactP}>Hos GK-Tømrer skræddersyer vi et godt tilbud til dig.</p>
            <p style={contactP}>Ring i dag på <span className="bold" style={{textDecoration: 'underline'}}>26 27 48 47</span></p>
        </section>
        </>
    )
}

const contactP = {
    fontFamily: "'Oswald', sans-serif",
    lineHeight: "2.5",
    fontSize: 18,
}

const ContactBottomStyle = {
    backgroundImage: `url(${WorkingImg})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    color: "#ddd"
}

export default Contact;