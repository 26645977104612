import React from "react";
import { HashLink as Link } from "react-router-hash-link";

// Assets
import Logo from "../../assets/GK-logo.png";
import FooterImg from "../../assets/footer_image.png";

const Footer = () => {
    return (
        <footer style={footerStyle}>
            <section className="row justify-content-around px-5">
                <article style={{color: "#ddd"}} className="col-lg-3 col-12">
                    <img style={logoStyle} className="img-fluid" src={Logo} alt="gk_logo" />
                    <h6 style={{padding: "25px 0 5px 15px", fontSize: 18}}>Få et uforpligtende tilbud</h6>
                    <p style={{padding: "0 15px", fontSize: 14, lineHeight: '2'}}>Vi træffes alle ugens dage på enten telefon eller e-mail.</p>
                </article>
                <article className="col-lg-3 col-12">
                    <h6 style={{color: "#ddd", paddingBottom: "15px", fontSize: 24}}>Kontakt GK</h6>
                    <div style={{color: "#ddd", fontSize: 14}}>
                        <p>GK-Tømrer v/ Gunner Knudsen <br></br> Fynslundvej 80, 6064 Jordrup</p>
                        
                        <p>Telefon: <span className="bold">26 27 48 47</span> <br></br>Email: <span className="bold">gunnergk@outlook.dk</span></p>
                        <p>CVR: 41892374</p>
                    </div>
                </article>
                <article className="col-lg-3 d-lg-block d-none">
                    <ul style={{listStyleType: "none"}}>
                        <li style={footerListStyle}><Link style={linkStyle} to="/#header">Forside</Link></li>
                        <li style={footerListStyle}><Link style={linkStyle} to="/kompetencer#services">Kompetencer</Link></li>
                        <li style={footerListStyle}><Link style={linkStyle} to="/galleri#galleri">Galleri</Link></li>
                        <li style={footerListStyle}><Link style={linkStyle} to="/om#om">Om GK</Link></li>
                        <li style={{paddingTop: 10}}><Link style={linkStyle} to="/kontakt#kontakt">Kontakt</Link></li>
                    </ul>
                </article>
                <div style={footerDivStyle} className="col-12"><p>Copyright 2021 &copy; <span className="bold">GK-Tømrer v/ Gunner Knudsen</span> | Kode af Mette Duedahl Knudsen</p></div>
            </section>
            
        </footer>
    )
}

const footerStyle = {
    backgroundImage: `url(${FooterImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#ddd",
    paddingTop: 40
}

const footerDivStyle = {
    borderTop: "1px solid #ddd",
    textAlign: "center",
    padding: "10px 0 0 0",
    fontSize: 14,
    marginTop: 20
}

const footerListStyle = {
    borderBottom: "1px solid #ddd",
    padding: "10px 0",
}

const linkStyle = {
    textDecoration: "none",
    color: "#ddd",
    fontFamily: "'Oswald', sans-serif"
}

const logoStyle = {
    maxWidth: "100px",
}

export default Footer;