import React from "react";
import { HashLink as Link } from "react-router-hash-link";

// Assets

import Logo from "../../assets/GK-logo.png";

const Nav = () => {

    return (
        <nav id="navbar" style={navbarStyle} className="navbar navbar-expand-lg navbar-light bg-light container-fluid py-2">
            <div className="container-fluid">
                <Link className="navbar-brand py-0 px-5 d-sm-block d-none" to="/"><img id="logo" src={Logo} alt="GK-Logo" style={GKLogoStyle} /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav" style={{ fontFamily: "'Oswald', sans-serif", fontWeight: "400", fontSize: "18px" }}>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item px-3">
                            <Link className="nav-link" aria-current="page" to="/#header">FORSIDE</Link>
                        </li>
                        <li className="nav-item px-3">
                            <Link className="nav-link" to="/kompetencer#services">KOMPETENCER</Link>
                        </li>
                        <li className="nav-item px-3">
                            <Link className="nav-link" to="/galleri#galleri">GALLERI</Link>
                        </li>
                        <li className="nav-item px-3">
                            <Link className="nav-link" to="/om#om">OM GK</Link>
                        </li>
                        <li className="nav-item px-3">
                            <Link className="nav-link" to="/kontakt#kontakt">KONTAKT</Link>
                        </li>
                    </ul>
                    <button style={navBtnStyle} className="py-0 mx-5 d-xl-block d-none" id="navBtn"><Link style={{ color: "#F8F9FA" }} id="light-link" className="nav-link" to="/kontakt">26 27 48 47</Link></button>
                </div>
            </div>
        </nav>
    )
}

const navbarStyle = {
    boxShadow: "0px 4px 5px 3px #F8F9FA",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1,
    transition: "box-shadow 0.4s"
}

const GKLogoStyle = {
    height: '65px',
    transition: "height 0.4s"
}

const navBtnStyle = {
    border: "none",
    backgroundColor: "#8E252C"
}

export default Nav;