import React from "react";
import { HashLink as Link } from "react-router-hash-link";

// Assets
import profileImg from "../../assets/portrait_2.png";

const About = () => {
    return (
        <main className="container-fluid pt-5" style={{backgroundColor: "#dbdddd"}} id="om">
            <section className="row">
                <article className="col-lg-8 col-12 py-5" id="aboutText">
                    <h2 className="mb-3" style={{color: "#333"}}>Om GK-Tømrer</h2>
                    <hr style={{border: "2px solid #8E252C", width: "100px"}}/>
                    <p style={{paddingRight: "20px"}}>
                        Jeg hedder Gunner Knudsen, og jeg er din lokale tømrer i Trekantsområdet.
                        Jeg har over 40 års erfaring som tømrer, i både Danmark samt i udlandet. 
                        GK-Tømrer blev grundlagt i November 2020, men jeg har tidligere været selvstændig
                        i mere end 10 år. 
                    </p>
                    <p>
                        En af kerneværdierne i mit firma er kvalitet. Jeg anvender
                        kvalitetsmaterialer, jeg laver altid et ordentligt stykke håndværk, 
                        og er ikke færdig før du er tilfreds.
                    </p>
                    <p>
                        GK-Tømrer er et lille firma, vi udfører derfor ikke storbyggerier men arbejder
                        hovedsageligt for private kunder - for os er ingen opgaver for små.
                    </p>
                    <p>
                        Skriv en mail eller tag en snak med Gunner og få et uforpligtende tilbud.
                    </p>
                    <Link to="/kontakt#kontakt" className="bold" style={{color: "#8E252C", textDecoration: "underline"}}>Kontakt GK-Tømrer</Link>
                </article>
                <article className="col-lg-4 d-lg-block d-none">
                    <img className="img-fluid" src={profileImg} alt="GK-portait" />
                </article>
            </section>
        </main>
    )
}

export default About;