import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Loading from "../Loading";

const Services = () => (
    <Query query={gql`
        {
        services{
            edges {
                node {
                    title
                    slug
                    serviceMeta {
                        content
                    }
                }
            }
            }
        }
    `
    }>
        {
            ({ loading, error, data }) => {
                if(loading){
                    return(
                        <>
                        <Loading />
                        </>
                    )
                }
                return (
                    <section className="row p-5 justify-content-around" style={{backgroundColor: "#ddd"}} id="services">
                        <article className="my-3 text-center">
                            <h3>Kompetencer</h3>
                            <hr style={{border: "2px solid #8E252C", width: "100px", margin: "15px auto 0 auto"}} />
                        </article>
                        {
                            data.services.edges.map((service, key) => {
                                return(
                                    <article key={key} className="col-lg-3 col-12 m-3 p-3 serviceHover" style={serviceStyle}>
                                        <h4 className="text-center">{service.node.title}</h4>
                                        <hr style={{border: "2px solid #8E252C", width: "100px", margin: "15px auto"}} />
                                        <p className="px-4">{service.node.serviceMeta.content}</p>
                                    </article>
                                )
                            })
                        }
                    </section>
                )

            }
        }

    </Query>
)

const serviceStyle = {
    backgroundColor: "#fff",
    borderRadius: 5
}

export default Services;