import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route} from "react-router-dom";

import "./App.css";

// Components

import Nav from "./components/layout/Nav";
import Footer from "./components/layout/Footer";

// Pages
import Index from "./components/pages/Index";
import Services from "./components/services/Services";
import Service from "./components/services/Service";
import Gallery from "./components/pages/Gallery";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";

const client = new ApolloClient({
  uri: "https://admin.xn--gk-tmrer-84a.dk/graphql",
})

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Nav />
        <Route exact path="/" component={Index} />
        <Route path="/kompetencer" component={Services} />
        <Route path="/kompetencer/:slug" component={Service} />
        <Route path="/galleri" component={Gallery} />
        <Route path="/om" component={About} />
        <Route path="/kontakt" component={Contact} />
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;
