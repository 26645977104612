import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import Loading from "../Loading";

class Service extends Component {
    render() {
        const props = this.props;

        if ( !props.data.service ){
            return (
                <>
                <p>Loading..</p>
                <Loading />
                </>
            );
        }

        return (
           <div>
               <h2>{props.data.service.title}</h2>
               <strong>{props.data.service.serviceMeta.content}</strong>
           </div> 
        );
    }
}

// <Link to={`/service/${service.node.slug}`}>Learn more</Link> Add to services to make individual sevice side

const GetServiceBySlug = gql`
query getServiceBySlug($slug: String) {
    service: serviceBy(uri: $slug){
           title
           serviceMeta {
               content
           }
       }
   }
`;

export default graphql(GetServiceBySlug, {
    options: (props) => {
        const slug = props.match.params.slug;
        return {
            variables: {
                slug
            }
        }
    }
})(Service);