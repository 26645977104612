import React from "react";

// Components
import Services from "../services/Services";
import Header from "../layout/Header";

// Assets
import workingV1 from "../../assets/GK_working.png";


const Index = () => {
    return (
        <>
            <Header />
            <main className="container-fluid px-0" style={{borderTop: "5px solid #8E252C"}}>
                <section className="row text-center justify-content-around m-5" id="indexContent">
                    <article style={indexArticles} className="col-lg-3 col-12 my-3 p-4 indexBoxes">
                        <i style={iconStyle} className="fas fa-tools mb-2"></i>
                        <h5>Godt dansk håndværk</h5>
                        <hr style={{border: "2px solid #8E252C", width: "100px", margin: "15px auto"}} />
                        <p className="px-3">Tømrermester med 40 års erfaring indenfor tømrerfaget. </p>
                    </article>
                    <article style={indexArticles} className="col-lg-3 col-12 my-3 p-4 indexBoxes">
                        <i style={iconStyle} className="far fa-clock mb-2"></i>
                        <h5>Hurtigt svar ved henvendelse</h5>
                        <hr style={{border: "2px solid #8E252C", width: "100px", margin: "15px auto"}} />
                        <p className="px-3">Vi besigtiger hurtigt opgaven, og vender tilbage med et tilbud.</p>
                    </article>
                    <article style={indexArticles} className="col-lg-3 col-12 my-3 p-4 indexBoxes">
                        <i style={iconStyle} className="fas fa-credit-card mb-2"></i>
                        <h5>Konkurrencedygtige priser</h5>
                        <hr style={{border: "2px solid #8E252C", width: "100px", margin: "15px auto"}} />
                        <p className="px-3">Lille firma uden de store udgifter, derfor kan vi tilbyde konkurrencedygtige priser</p>
                    </article>
                </section>
                <section style={indexTextBox} className="p-5" id="indexTextBox">
                    <h3>Få et uforpligtende tilbud</h3>
                    <hr style={{border: "2px solid #ddd", width: "200px", margin: "15px auto"}} />
                    <p style={{fontSize: 20}}>
                        GK-Tømrer er din lokale håndværker i trekantsområdet. 
                        Vi udfører en bred vifte af tømrer- og snedkeropgaver for private eller som underentreprenør. 
                        Har du brug for en tømrer med over 40 års erfaring?
                        Så kontakt GK-Tømrer i dag.
                     </p>
                </section>
                <Services />               
            </main>
        </>
    )
}

const indexArticles = {
    backgroundColor: "#DBDDDD",
    borderRadius: 5,
}

const iconStyle = {
    fontSize: "35px",
    color: "#8E252C"
}

const indexTextBox = {
    backgroundImage: `url(${workingV1})`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    color: "#ddd",
    textAlign: "center",
    fontFamily: "'Oswald', sans-serif"
}

export default Index;