import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Loading from "../Loading";

const Gallery = () => (
    <Query query={gql`
    {
        mediaItems {
          edges {
            node {
              sourceUrl
            }
          }
        }
      }
    `
    }>
        {
            ({ loading, data }) => {
                if (loading) {
                    return (
                        <>
                            <Loading />
                        </>
                    )
                }
                return (
                    <main className="container-fluid p-5 text-center" style={{ backgroundColor: "#dbdddd" }} id="galleri">
                        <section>
                            <h2 className="mt-5" style={{ color: "#333" }}>Galleri</h2>
                            <hr style={{ border: "2px solid #8E252C", width: "200px", margin: "20px auto" }} />
                        </section>
                        <section className="row">
                        {
                            data.mediaItems.edges.map((img, key) => {
                                return (
                                    <>
                                    <article key={key} className="col-lg-4 col-12 p-3 text-center">
                                        <div style={{backgroundImage: `url(${img.node.sourceUrl})`, backgroundSize: "cover", backgroundPosition: "center center", height: 200}}></div>
                                    </article>
                                    </>
                                )
                            })
                        }
                        </section>
                    </main>
                )

            }
        }

    </Query>
)

export default Gallery;