import React from "react";
import "./Loading.css";

const Loading = () => {
    return (
        <div style={loadingStyle}>
            <div className="loadingio-spinner-spin-nde9fybqol"><div className="ldio-y8jztjpe2qe">
                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div></div>
        </div>
    )
}

const loadingStyle = {
    backgroundColor: "#ddd",
    textAlign: "center",
    padding: "300px 0"
}

export default Loading;