import React from "react";
import { Link } from "react-router-dom";

// Assets
import Cover from "../../assets/GK_coverbillede_v2.png";

const Header = () => {
    return (
        <header style={headerStyle} id="header">
            <div className="container text-center py-5">
                <h1>GK-Tømrer v/ Gunner Knudsen</h1>
                <p className="py-2" style={{fontSize: 18}}>Din lokale tømrer i Kolding, Fredericia, Vejle og omegn.</p>
                <div className="py-3">
                    <Link to="/kontakt" style={buttonStyle}>Få et tilbud</Link><Link to="/kontakt" style={buttonStyle} className="d-lg-inline d-none">26274847</Link>
                </div>
            </div>
        </header>
    )
}

const headerStyle = {
    backgroundColor: "black",
    backgroundImage: `url(${Cover})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#ddd",
    padding: "100px 0"
}

const buttonStyle = {
    backgroundColor: "transparent",
    border: "2px solid #ddd",
    color: "#ddd",
    padding: "10px 15px",
    fontWeight: "bold",
    fontFamily: "'Oswald', sans-serif",
    margin: "0 10px",
}

export default Header;